import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-signin',
  templateUrl: './valid-session.component.html',
  styleUrls: ['./valid-session.component.scss']
})
export class ValidSession implements OnInit{

  hash: any;
  token: any;
  loading = true;
  form: FormGroup;
  error = false;
  errorMessage = '';
  credentials: any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService) {}


  ngOnInit(): any {
    try {
      let lang = localStorage.getItem('language');
      lang !== null ? this.translate.use(lang) : this.translate.use(navigator.language.toLocaleLowerCase());
      this.hash = window.location.hash;
      if (this.hash.indexOf("?") > 0) {
        this.hash.split("?")
        this.token = this.hash.split("?")[1].split("=")[1]
        this.session(this.token)
        return this.token
      }
      return this.error = true;
    } catch (error) {
      this.error = true;
    }

    window.sessionStorage.clear()
  }

  session(token) {
    this.authService.session(token).then(res => {
      let userLanguage = JSON.parse(sessionStorage.getItem('credentials'))
      if (this.authService.isAuthenticated() && userLanguage.usuarioIdioma) {
        this.translate.use(userLanguage.usuarioIdioma);
        this.translate.setDefaultLang(userLanguage.usuarioIdioma);
      }
    })
      .catch(err => {
        this.error = true;
      })
  }

}
