import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  loading = false;
  form: FormGroup;
  error = false;
  errorMessage = '';
  credentials: any;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    public translate: TranslateService) { }

  switchLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('language', lang);
  }

  async onSignin() {
    this.loading = true;
    await this.authService.signIn(this.form.value)
    this.loading = false;
    let userLanguage = JSON.parse(sessionStorage.getItem('credentials'))
    if (this.authService.isAuthenticated() && userLanguage) {
      this.translate.use(userLanguage.user.usuarioIdioma);
      this.translate.setDefaultLang(userLanguage.user.usuarioIdioma);
      localStorage.setItem('language', userLanguage.user.usuarioIdioma)
    }
  }

  ngOnInit(): any {
    let lang = localStorage.getItem('language');
    lang !== null ? this.translate.use(lang) : this.translate.use(navigator.language.toLocaleLowerCase());
    window.sessionStorage.clear()
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
  }
}
