import { Injectable } from '@angular/core';

@Injectable()
export class SessionService {

  DB = window.sessionStorage  

  constructor()
  {
    
  } 

  get(key) 
  {
    return JSON.parse(this.DB.getItem(key))
  }

  create(key, data)
  {
    return this.DB.setItem(key, JSON.stringify(data))
  }

  update(key, data)
  {
    return this.DB.setIten(key, JSON.stringify(data))
  }

  delete(key)
  {
    return this.DB.removeItem(key)
  }


}