import { Injectable, EventEmitter } from '@angular/core';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { DatabaseService } from '../services/datadase.service';
import { NbToastrService } from '@nebular/theme';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Rx';

import { User } from './user.interface';
import { MessagingService } from '../services/push.service';

@Injectable()
export class AuthService {
  message;
  public showNavBarEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  private authenticated = false;
  credentials: any
  constructor(private router: Router, public webService: DatabaseService, private toastrService: NbToastrService, private messagingService: MessagingService) {}
  types: NbToastStatus[] = [
    NbToastStatus.WARNING,
    NbToastStatus.SUCCESS
  ];
  async signIn(user: User)
  {
    let tokenData = await this.webService.login(user.email, user.password)
    .catch(err => {
      this.toastrService.show(
        'Falha ao realizar login',
        `Falha`,
        {
          status: this.types[0],
          duration: 4000
        }
      );
    })
    if(tokenData){
      this.handlerSucess(tokenData)
    }
  }
  handlerSucess(c)
  {
    console.log(JSON.stringify(c));
    window.sessionStorage.setItem("credentials", JSON.stringify(c))
    setTimeout(() => {
      // const userId = c['user']['idUsuario'];
      // const tokenFCM = this.messagingService.requestPermission(userId)
      // window['tokenFCM'] = tokenFCM;
      // this.messagingService.receiveMessage()
      // this.message = this.messagingService.currentMessage
      // console.log(this.message)
      window.sessionStorage.setItem("credentials", JSON.stringify(c))
      this.router.navigate(['pages/dashboard']);
    }, 100)
  }
  logout()
  {
    window.sessionStorage.clear();
    this.showNavBar(false);
    this.router.navigate(['signin']);
  }
  isAuthenticated()
  {
    let isSessionActive = window.sessionStorage.getItem("credentials")
    if(isSessionActive){
      return true
    }else{
      return false
    }
  }
  private showNavBar(ifShow: boolean)
  {
     this.showNavBarEmitter.emit(ifShow);
  }
  async session(token){
    const sessionData = await this.webService.validSession(token)
    this.handlerSucess(sessionData)
    return sessionData
  }
}
