import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { NbToastrService } from '@nebular/theme';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/operator/catch';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Http} from "@angular/http";

@Injectable()
export class DatabaseService {


  /**
   * @todo isso aqui precisa ir para envs de ambiente
   * @description refatorar e colocar isso dentro do seed ANGULAR_APP_${ENV_NAME}
  */
  private urlWSV: string = 'https://wsv.expensemobi.com.br/ExpenseMobilityFinal/portal';
  private urlAPI: string = 'https://api-reportbi-hm.expensemobi.com.br';
  private API_X_WING: string = 'https://api.expensemobi.com.br';
  private urlApiDash: string = 'https://api.expensemobi.com.br/dashboard';
  private urlAtualizaStatus: string = ' https://atualiza.stuo.com.br';
  private urlRestauraImagem: string = 'https://restoreimages.stuo.com.br';
  private urlReprocessarBI: string = 'https://report-bi-worker.expensemobi.com.br';

  query: any;
  data: Observable<any>;
  token: any;
  userId: any;
  comapnyId: any;
  session: any;
  message: String;

  constructor(private toastrService: NbToastrService, private http: Http) {
  }

  types: NbToastStatus[] = [
    NbToastStatus.WARNING,
  ];

  getUserId() {
    this.session = JSON.parse(window.sessionStorage.getItem('credentials'));
    if (this.session) {
      this.userId = this.session.user.idUsuario;
    }
    return this.userId;
  }

  getCompanyId() {
    this.session = JSON.parse(window.sessionStorage.getItem('credentials'));
    if (this.session) {
      this.comapnyId = this.session.user.idEmpresa;
    }
    return this.comapnyId;
  }

  getToken() {
    this.session = JSON.parse(window.sessionStorage.getItem('credentials'));
    if (this.session) {
      this.token = this.session.accessToken;
    }
    return this.token;
  }

  handlerMessage(error) {
    for (let k in error) {
      if (typeof error[k] === 'object') {
        if (error[k] !== null) {
          if (error[k].length > 0) {
            if (error[k][0].message !== undefined) {
              this.message = error[k][0].message;
            }
          }
        }
      }
    }
    return this.message;
  }

  /**
   *
   * BI SERVICES
   */
  login(email, password) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.urlAPI}/login`, {email, password})
        .subscribe(
          result => resolve(result.json()),
          error => {
            reject(error);
          },
        );
    });
  }

  validSession(token) {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.urlAPI}/session/${token}`)
        .subscribe(
          result => resolve(result.json()),
          error => {
            reject(error);
          },
        );
    });
  }

  getTokenDash() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.urlApiDash}/dash/auth/${this.getToken()}`)
        .subscribe(
          result => resolve(result.json()),
          error => {
            reject(error);
          },
        );
    });
  }

  atualizaStatus(params: any) {
    const header: any = {'headers':
        {
          'Content-Type': 'application/json'
        }
    };
    return new Promise((resolve, reject) => {
      this.http.post(`${this.urlAtualizaStatus}/despesa/atualizar/status`, params, header)
        .subscribe(
          (result: any) => resolve(result),
          (error: any) => reject(error),
        );
    });
  }

  restauraImagens(params: any) {
    const header: any = {'headers':
        {
          'Content-Type': 'application/json'
        }
    };
    return new Promise((resolve, reject) => {
      this.http.post(`${this.urlRestauraImagem}/restaurar/glacier`, params, header)
        .subscribe(
          (result: any) => resolve(result.json()),
          (error: any) => {
            console.log(error);
          },
        );
    });
  }

  reprocessBI(params: any) {
    const headers: any =
        {
          'Authorization': '8c20d09ef8428294b51777b3a51428d3',
          'Content-Type': 'application/json'
        };
    const options = { headers: headers}
    return new Promise((resolve, reject) => {
      this.http.post(`${this.urlReprocessarBI}/reports/api/expenses/enqueue`, params, options)
        .subscribe(
          (result: any) => resolve(result),
          (error: any) => reject(error),
        );
    });
  }


  report(params, typeAnalyse) {
    const header: any = {'headers': {'token': this.getToken()}};
    return new Promise((resolve, reject) => {
      this.http.post(`${this.urlAPI}/report/${typeAnalyse}`, params, header)
        .subscribe(
          result => resolve(result.json()),
          error => resolve([]),
        );
    });
  }

  reportAdvanced(params) {
    const header: any = {'headers': {'token': this.getToken()}};
    return new Promise((resolve, reject) => {
      this.http.post(`${this.API_X_WING}/advanced/list`, params, header)
        .subscribe(
          result => resolve(result.json()),
          error => resolve([]),
        );
    });
  }

  reportUsers(params: any) {
    const header: any = {'headers': {'token': this.getToken()}};
    return new Promise((resolve, reject) => {
      this.http.post(`${this.API_X_WING}/users-exp/list`, params, header)
        .subscribe(
          result => resolve(result.json()),
          error => resolve([]),
        );
    });
  }

  generatePdf(guid, analyze, sendmessageid, isEmail) {
    const header: any = {
      'headers': {
        'token': this.getToken(),
      },
    };
    return new Promise((resolve, reject) => {
      const params = {
        reportName: analyze,
        guid,
        isEmail,
      };
      try {
        this.http.post(`${this.urlAPI}/pdf/queue`, params, header).subscribe(
          (result) => resolve(result.json()),
          (error) => {
            resolve(null);
          },
        );
      } catch (error) {
        alert('ERRORRR');
      }
    });
  }

  getcolumns(typeAnalyse) {
    const header: any = {
      'headers': {
        'token': this.getToken(),
      },
    };
    return new Promise((resolve, reject) => {
      try {
        this.http.get(`${this.urlAPI}/configurations/${typeAnalyse}`, header)
          .subscribe(
            result => resolve(result.json()),
            error => resolve(null),
          );
      } catch (error) {
        alert('ERRORRR');
      }
    });
  }

  getAgender(typeAnalyse) {
    const header: any = {
      'headers': {
        'token': this.getToken(),
      },
    };
    return new Promise((resolve, reject) => {
      try {
        this.http.get(`${this.urlAPI}/admin/configurations/agender/${typeAnalyse}`, header)
          .subscribe(
            result => resolve(result.json()),
            error => {
              resolve(null);
            },
          );
      } catch (error) {
        alert('ERRORRR');
      }
    });
  }

  saveColumns(data, typeReport) {
    const header: any = {'headers': {'token': this.getToken()}};
    return new Promise((resolve, reject) => {
      this.http.post(`${this.urlAPI}/admin/configurations/columns/${typeReport}`, data, header)
        .subscribe(
          result => resolve(result.json()),
          error => {
            reject(error);
          },
        );
    });
  }

  saveCalendar(data, typeReport) {
    const header: any = {'headers': {'token': this.getToken()}};
    return new Promise((resolve, reject) => {
      this.http.post(`${this.urlAPI}/admin/configurations/agender/${typeReport}`, data, header)
        .subscribe(
          result => resolve(result.json()),
          error => {
            reject(error);
          },
        );
    });
  }

  checkFile(guid) {
    const header: any = {'headers': {'token': this.getToken()}};
    return new Promise((resolve, reject) => {
      this.http.get(`${this.urlAPI}/pdf/check/${guid}`, header)
        .subscribe(
          result => resolve(result.json()),
          error => {
            reject(error);
          },
        );
    });
  }

  /** PORTAL WS */

  listarEmpresaAcesso() {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.urlWSV}`, {
        'method': 'listarEmpresasAcesso',
        'key': '>uV6P(B4rKoQPjeAl=Mqv]|`FO;eG5XNH}?W%T($34>3c6%jC^gxN4-53[j4L=1',
        'param': {'idempresamatriz': this.getCompanyId(), 'idusuariologado': this.getUserId()}
      })
        .subscribe(res => {
          const response = JSON.parse(res.json()['response']);
          if (response['erro'] == undefined) {
            resolve(JSON.parse(res.json()['response']));
          } else {
            resolve([]);
          }
        });
    });
  }

  listarUsuario(comapnyId: number, flativo: boolean, allUsers: boolean) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.urlWSV}`, {
        'method': 'listarUsuario',
        'key': '>uV6P(B4rKoQPjeAl=Mqv]|`FO;eG5XNH}?W%T($34>3c6%jC^gxN4-53[j4L=1',
        'param': {
          'idempresa': this.getCompanyId(),
          'idusuariologado': this.getUserId(),
          flativo,
          'idempresaselecionada': comapnyId,
          todos: allUsers
        }
      })
        .subscribe(res => {
          const reponse = JSON.parse(res.json()['response']);
          if (reponse['erro'] == undefined) {
            resolve(JSON.parse(res.json()['response']));
          } else {
            resolve([]);
          }
        });
    });
  }

  listarCategoriaContaContabil(comapnyId) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.urlWSV}`, {
        'method': 'listarCategoriaContaContabil',
        'key': '>uV6P(B4rKoQPjeAl=Mqv]|`FO;eG5XNH}?W%T($34>3c6%jC^gxN4-53[j4L=1',
        'param': {
          'idempresa': this.getCompanyId(),
          'idusuariologado': this.getUserId(),
          'flativo': true,
          'idempresaselecionada': comapnyId
        }
      })
        .subscribe(res => {
          const reponse = JSON.parse(res.json()['response']);
          if (reponse['erro'] == undefined) {
            resolve(JSON.parse(res.json()['response']));
          } else {
            resolve([]);
          }
        });
    });
  }

  listarRelatorios(comapnyId) {
    return new Promise((resolve, reject) => {
      const reportsFilter = [
        {
          'nomerelatorio': 'mileage',
          'typeAnalyse': 'mileage',
          'PDF': true,
        },
        {
          'nomerelatorio': 'generalWithoutReceipt',
          'typeAnalyse': 'geral',
          'PDF': true,
        },
        {
          'nomerelatorio': 'generalWithReceipt',
          'typeAnalyse': 'geralReceipt',
          'PDF': true,
        },
        {
          'nomerelatorio': 'advanceRequest',
          'typeAnalyse': 'advanced',
          'PDF': true,
        },
        {
          'nomerelatorio': 'userReport',
          'typeAnalyse': 'userReport',
          'PDF': false,
        },
      ];
      if (comapnyId == 16) {
        reportsFilter[0]['typeAnalyse'] = 'mileageUnisys'
      }
      resolve(reportsFilter);
    });
  }

  listarFormasPagamento(comapnyId) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.urlWSV}`, {
        'method': 'listarFormasPagamento',
        'key': '>uV6P(B4rKoQPjeAl=Mqv]|`FO;eG5XNH}?W%T($34>3c6%jC^gxN4-53[j4L=1',
        'param': {
          'idempresa': this.getCompanyId(),
          'idusuariologado': this.getUserId(),
          'flativo': true,
          'idempresaselecionada': comapnyId
        }
      })
        .subscribe(res => {
          const reponse = JSON.parse(res.json()['response']);
          if (reponse['erro'] == undefined) {
            resolve(JSON.parse(res.json()['response']));
          } else {
            resolve([]);
          }
        });
    });
  }

  listarEmpresasDoGrupo(comapnyId) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.urlWSV}`, {
        'method': 'listarEmpresasDoGrupo',
        'key': '>uV6P(B4rKoQPjeAl=Mqv]|`FO;eG5XNH}?W%T($34>3c6%jC^gxN4-53[j4L=1',
        'param': {'idempresamatriz': this.getCompanyId()}
      })
        .subscribe(res => {
          const reponse = JSON.parse(res.json()['response']);
          if (reponse['erro'] == undefined) {
            resolve(JSON.parse(res.json()['response']));
          } else {
            resolve([]);
          }
        });
    });
  }

  listarCentrosDeCustos(comapnyId) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.urlWSV}`, {
        'method': 'listarCentrosDeCustos',
        'key': '>uV6P(B4rKoQPjeAl=Mqv]|`FO;eG5XNH}?W%T($34>3c6%jC^gxN4-53[j4L=1',
        'param': {
          'idempresa': this.getCompanyId(),
          'idusuariologado': this.getUserId(),
          'flativo': true,
          'idempresaselecionada': comapnyId
        }
      })
        .subscribe(res => {
          const reponse = JSON.parse(res.json()['response']);
          if (reponse['erro'] == undefined) {
            resolve(JSON.parse(res.json()['response']));
          } else {
            resolve([]);
          }
        });
    });
  }

  listarClientes(comapnyId) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.urlWSV}`, {
        'method': 'listarClientes',
        'key': '>uV6P(B4rKoQPjeAl=Mqv]|`FO;eG5XNH}?W%T($34>3c6%jC^gxN4-53[j4L=1',
        'param': {'idempresa': this.getCompanyId(), 'flativo': false}
      })
        .subscribe(res => {
          const reponse = JSON.parse(res.json()['response']);
          if (reponse['erro'] == undefined) {
            resolve(JSON.parse(res.json()['response']));
          } else {
            resolve([]);
          }
        });
    });
  }

  listarProjetos(comapnyId) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.urlWSV}`, {
        'method': 'listarProjetos',
        'key': '>uV6P(B4rKoQPjeAl=Mqv]|`FO;eG5XNH}?W%T($34>3c6%jC^gxN4-53[j4L=1',
        'param': {
          'idempresa': this.getCompanyId(),
          'idusuariologado': this.getUserId(),
          'flativo': true,
          'idempresaselecionada': comapnyId
        }
      })
        .subscribe(res => {
          const reponse = JSON.parse(res.json()['response']);
          if (reponse['erro'] == undefined) {
            resolve(JSON.parse(res.json()['response']));
          } else {
            resolve([]);
          }
        });
    });
  }

  listarTiposDespesa(comapnyId) {
    return new Promise((resolve, reject) => {
      this.http.post(`${this.urlWSV}`, {
        'method': 'listarTiposDespesa',
        'key': '>uV6P(B4rKoQPjeAl=Mqv]|`FO;eG5XNH}?W%T($34>3c6%jC^gxN4-53[j4L=1',
        'param': {'idempresa': this.getCompanyId(), 'flativo': true}
      })
        .subscribe(res => {
          const reponse = JSON.parse(res.json()['response']);
          if (reponse['erro'] == undefined) {
            resolve(JSON.parse(res.json()['response']));
          } else {
            resolve([]);
          }
        });
    });
  }

  listarDepartamentos(comapnyId: number) {
    return new Promise((resolve) => {
      this.http.post(`${this.urlWSV}`, {
        'method': 'listarDepartamentos',
        'key': '>uV6P(B4rKoQPjeAl=Mqv]|`FO;eG5XNH}?W%T($34>3c6%jC^gxN4-53[j4L=1',
        'param': {'idempresa': this.getCompanyId(), 'flativo': true, 'todos': true, 'idempresaselecionada': comapnyId}
      })
        .subscribe(res => {
          const response = JSON.parse(res.json()['response']);
          if (!response['erro']) resolve(response.sort((a: any, b: any) => a.descdepartamento.localeCompare(b.descdepartamento)));
          if (response['erro']) resolve([]);
          if (!response) resolve([]);
        });
    });
  }

  listarCargos(comapnyId: number) {
    return new Promise((resolve) => {
      this.http.post(`${this.urlWSV}`, {
        'method': 'listarCargos',
        'key': '>uV6P(B4rKoQPjeAl=Mqv]|`FO;eG5XNH}?W%T($34>3c6%jC^gxN4-53[j4L=1',
        'param': {'idempresa': this.getCompanyId(), 'flativo': true, 'todos': true, 'idempresaselecionada': comapnyId}
      })
        .subscribe(res => {
          const response = JSON.parse(res.json()['response']);
          if (!response['erro']) resolve(response.sort((a: any, b: any) => a.desccargo.localeCompare(b.desccargo)));
          if (response['erro']) resolve([]);
          if (!response) resolve([]);
        });
    });
  }

  listarPerfisAcesso(comapnyId: number) {
    return new Promise((resolve) => {
      this.http.post(`${this.urlWSV}`, {
        'method': 'listarPerfisAcesso',
        'key': '>uV6P(B4rKoQPjeAl=Mqv]|`FO;eG5XNH}?W%T($34>3c6%jC^gxN4-53[j4L=1',
        'param': {'idempresa': this.getCompanyId(), 'flativo': true, 'todos': true, 'idempresaselecionada': comapnyId}
      })
        .subscribe(res => {
          const response = JSON.parse(res.json()['response']);
          if (!response['erro']) resolve(response.sort((a: any, b: any) => a.descperfilacesso.localeCompare(b.descperfilacesso)));
          if (response['erro']) resolve([]);
          if (!response) resolve([]);
        });
    });
  }

  listarGruposAprovacao() {
    return new Promise((resolve) => {
      this.http.post(`${this.urlWSV}`, {
        'method': 'listarGruposAprovacao',
        'key': '>uV6P(B4rKoQPjeAl=Mqv]|`FO;eG5XNH}?W%T($34>3c6%jC^gxN4-53[j4L=1',
        'param': {'idempresa': this.getCompanyId(), 'flativo': true}
      })
        .subscribe(res => {
          const response = JSON.parse(res.json()['response']);

          if (!response['erro']) resolve(response.sort((a: any, b: any) => a.descgrupoaprovacao.localeCompare(b.descgrupoaprovacao)));
          if (response['erro']) resolve([]);
          if (!response) resolve([]);
        });
    });
  }

  listarBancos() {
    return new Promise((resolve) => {
      this.http.post(`${this.urlWSV}`, {
        'method': 'listarBancos',
        'key': '>uV6P(B4rKoQPjeAl=Mqv]|`FO;eG5XNH}?W%T($34>3c6%jC^gxN4-53[j4L=1',
        'param': {'idempresa': this.getCompanyId(), 'flativo': true}
      })
        .subscribe(res => {
          const response = JSON.parse(res.json()['response']);

          if (!response['erro']) resolve(response.sort((a: any, b: any) => a.descbanco.localeCompare(b.descbanco)));
          if (response['erro']) resolve([]);
          if (!response) resolve([]);
        });
    });
  }
}
