import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { UserData } from '../../../@core/data/users';
import { AnalyticsService } from '../../../@core/utils';
import { LayoutService } from '../../../@core/utils';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: any;
  session: any;
  userMenu = [{ title: this.translate.instant('signOut'), link: 'auth/signin'}];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private userService: UserData,
              private analyticsService: AnalyticsService,
              private layoutService: LayoutService,
              public translate: TranslateService
              ) {
                this.session = window.sessionStorage.getItem("credentials")
  }
  ngOnInit() {
    this.user = {
      email: JSON.parse(this.session).user.email,
      enderecoImagem: `https://s3.amazonaws.com/expense-mobi-arquivos/imagenslogo/${JSON.parse(this.session).user.empresa.enderecoImagem}`
    }
  }
  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }
  onMenuItemClick(){
    alert()
  }
  goToHome() {
    this.menuService.navigateHome();
  }
  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
  showNotifications(){
    
  }
}
